<template>
  <div>
    <b-overlay v-if="showOverlayRelation" :show="showOverlayRelation">
      <b-row>
        <b-col
          cols="12"
          style="min-height: 300px"
        />
      </b-row>
    </b-overlay>
    <b-row v-if="!showOverlayRelation">
      <b-col cols="12">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormRelationObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(updateRelation)"
          >
            <b-row class="match-height">
              <b-col
                cols="12"
              >
                <b-card title="Correspondentieadres">
                  <b-row>
                    <b-col
                      cols="12"
                      md="5"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="postalCode|postalcode"
                        rules="required"
                      >
                        <b-form-group
                          label="Postcode"
                          label-for="postalCode"
                        >
                          <b-form-input
                            id="postalCode"
                            v-model="relation.postalCode"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="houseNumber"
                        rules="required|integer"
                      >
                        <b-form-group
                          label="Huisnummer"
                          label-for="houseNumber"
                        >
                          <b-form-input
                            id="houseNumber"
                            v-model="relation.houseNumber"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="houseNumberAddition"
                        rules=""
                      >
                        <b-form-group
                          label="Huisnummertoevoeging"
                          label-for="houseNumberAddition"
                        >
                          <b-form-input
                            id="houseNumberAddition"
                            v-model="relation.houseNumberAddition"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="street"
                        rules=""
                      >
                        <b-form-group
                          label="Straat"
                          label-for="street"
                        >
                          <b-form-input
                            id="street"
                            v-model="relation.street"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="city"
                        rules=""
                      >
                        <b-form-group
                          label="Plaats"
                          label-for="city"
                        >
                          <b-form-input
                            id="city"
                            v-model="relation.city"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card title="Contactgegevens">
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                      class="mb-1"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="gender"
                        rules=""
                      >
                        <label>Geslacht</label>
                        <div class="demo-inline-spacing">
                          <b-form-radio
                            v-model="relation.gender"
                            name="gender"
                            value="m"
                            class="mt-0"
                            :state="getValidationState(validationContext)"
                          >
                            Man
                          </b-form-radio>
                          <b-form-radio
                            v-model="relation.gender"
                            name="gender"
                            value="f"
                            class="mt-0"
                            :state="getValidationState(validationContext)"
                          >
                            Vrouw
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="firstName"
                        rules=""
                      >
                        <b-form-group
                          label="Voornaam"
                          label-for="firstName"
                        >
                          <b-form-input
                            id="firstName"
                            v-model="relation.firstName"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        label="Tussenvoegsel"
                        label-for="middleName"
                      >
                        <b-form-input
                          id="middleName"
                          v-model="relation.middleName"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="5"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="lastName"
                        rules="required"
                      >
                        <b-form-group
                          label="Achternaam"
                          label-for="lastName"
                        >
                          <b-form-input
                            id="lastname"
                            v-model="relation.lastName"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="birthDate"
                        rules=""
                      >
                        <b-form-group
                          label="Geboortedatum"
                          label-for="birthDate"
                          :state="getValidationState(validationContext)"
                        >
                          <flat-pickr
                            id="birthDate"
                            v-model="relation.birthDate"
                            :config="birthDateConfig"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="phoneNumber"
                        rules="required|integer|min:10"
                      >
                        <b-form-group
                          label="Telefoonnummer"
                          label-for="phoneNumber"
                        >
                          <b-form-input
                            id="phoneNumber"
                            v-model="relation.phoneNumber"
                            type="text"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="mobileNumber"
                        rules="required|integer|min:10"
                      >
                        <b-form-group
                          label="Mobiel nummer"
                          label-for="mobileNumber"
                        >
                          <b-form-input
                            id="mobileNumber"
                            v-model="relation.mobileNumber"
                            type="text"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="email"
                        rules="required"
                      >
                        <b-form-group
                          label="E-mailadres"
                          label-for="email"
                        >
                          <b-form-input
                            id="email"
                            v-model="relation.email"
                            type="email"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card
                  v-if="relation.type === 2"
                  title="Bedrijfsgegevens"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Bedrijfsnaam"
                        rules="required"
                      >
                        <b-form-group
                          label="Bedrijfsnaam"
                          label-for="companyName"
                        >
                          <b-form-input
                            id="companyName"
                            v-model="relation.companyName"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="companyCocNumber"
                        rules="required"
                      >
                        <b-form-group
                          label="KVK-nummer"
                          label-for="companyCocNumber"
                        >
                          <b-form-input
                            id="cocNumber"
                            v-model="relation.companyCocNumber"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="contactPerson"
                        rules="required"
                      >
                        <b-form-group
                          label="Contactpersoon"
                          label-for="contactPerson"
                        >
                          <b-form-input
                            id="contactPerson"
                            v-model="relation.contactPerson"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card title="Betaalgegevens">
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      class="mb-1"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="bankAccountNumber"
                        rules="required|iban"
                      >
                        <b-form-group
                          label="IBAN"
                          label-for="bankAccountNumber"
                        >
                          <b-form-input
                            v-model="relation.bankAccountNumber"
                            name="bankAccountNumber"
                            :state="getValidationState(validationContext)"
                            trim
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="debtorName"
                        rules="required"
                      >
                        <b-form-group
                          label="Tenaamstelling"
                          label-for="ibanAscription"
                        >
                          <b-form-input
                            id="debtorName"
                            v-model="relation.debtorName"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="paymentMethod"
                        rules="required"
                      >
                        <b-form-group
                          label="Betaalwijze"
                          label-for="paymentMethod"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="relation.paymentMethod"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="paymentMethodOptions"
                            :clearable="false"
                            input-id="paymentMethod"
                            :reduce="val => val.value"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-card>
                  <b-row>
                    <b-col
                      cols="6 text-left"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="secondary"
                        @click="$router.go(-1)"
                      >
                        <span>Terug</span>
                      </b-button>
                    </b-col>
                    <b-col
                      cols="6 text-right"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        type="submit"
                        :disabled="updateSpinner"
                      >
                        <b-spinner
                          v-if="updateSpinner"
                          small
                          class="mr-1"
                        />
                        <span>Opslaan</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadio, BButton, BForm, BSpinner, BOverlay,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import relationsStoreModule from '@/views/pages/relations/relationsStoreModule'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  alphaNum,
  email,
  required,
  date,
  postalcode,
  iban,
  min,
} from '@core/utils/validations/validations'
import useRelationsList from '@/views/pages/relations/useRelationsList'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {
  todayMinYears,
} from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadio,
    flatPickr,
    BButton,
    BForm,
    BSpinner,
    vSelect,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      date,
      postalcode,
      iban,
      min,
      relation: {
        id: null,
        type: 0, // 1 personal / 2 business
        gender: 'm', // m of f
        firstName: null,
        middleName: null,
        lastName: null,
        birthDate: null,
        phoneNumber: null,
        mobileNumber: null,
        email: null,
        companyName: null,
        companyCocNumber: null,
        street: null,
        houseNumber: null,
        houseNumberAddition: null,
        postalCode: null,
        city: null,
        paymentMethod: null,
        debtorName: null,
        bankAccountType: 'IBAN',
        bankAccountNumber: null,
      },
      birthDateConfig: {
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      paymentMethodOptions: [
        { label: 'Automatische incasso', value: 'AUTOMATICCOLLECTION' },
      ],
      updateSpinner: false,
      showOverlayRelation: true,
    }
  },
  beforeMount() {
    this.getRelation()
  },
  methods: {
    updateRelation() {
      this.updateSpinner = true
      store.dispatch('apps-relations-edit/updateRelation', this.relation)
        .then(() => {
          this.updateSpinner = false
          this.$swal({
            icon: 'success',
            title: 'Relatie geupdated!',
            text: `${errorAndSuccesDefaultMessage.updateDataSucces}`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.updateSpinner = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het updaten van de relatie',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getRelation() {
      this.showOverlayRelation = true
      store.dispatch('apps-relations-edit/fetchRelation', { id: router.currentRoute.params.id })
        .then(response => {
          this.relation = response.data
          this.showOverlayRelation = false
        })
        .catch(() => {
          this.showOverlayRelation = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Relatie',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het later nog een keer.',
            },
          })
        })
    },
  },
  setup() {
    const RELATION_APP_STORE_MODULE_NAME = 'apps-relations-edit'

    // Register module
    if (!store.hasModule(RELATION_APP_STORE_MODULE_NAME)) store.registerModule(RELATION_APP_STORE_MODULE_NAME, relationsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RELATION_APP_STORE_MODULE_NAME)) store.unregisterModule(RELATION_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const {
      resolveDistrictForCountry,
      countryOptions,
      districtOptions,
      legalFormOptions,
      idTypeOptions,
      martialOptions,
    } = useRelationsList()

    return {
      refFormObserver,
      resolveDistrictForCountry,
      getValidationState,
      resetForm,
      countryOptions,
      districtOptions,
      legalFormOptions,
      idTypeOptions,
      todayMinYears,
      martialOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-organisation-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
